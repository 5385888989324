const DSGVO = (
  <>
    <h1>Datenschutzerklärung</h1>
    <p>Stand: 16. Februar 2024</p>
    <h2>Inhaltsübersicht</h2>
    <ul className="index">
      <li>
        <a className="index-link" href="#m3">
          Verantwortlicher
        </a>
      </li>
      <li>
        <a className="index-link" href="#mOverview">
          Übersicht der Verarbeitungen
        </a>
      </li>
      <li>
        <a className="index-link" href="#m2427">
          Maßgebliche Rechtsgrundlagen
        </a>
      </li>
      <li>
        <a className="index-link" href="#m27">
          Sicherheitsmaßnahmen
        </a>
      </li>
      <li>
        <a className="index-link" href="#m25">
          Übermittlung von personenbezogenen Daten
        </a>
      </li>
      <li>
        <a className="index-link" href="#m24">
          Internationale Datentransfers
        </a>
      </li>
      <li>
        <a className="index-link" href="#m10">
          Rechte der betroffenen Personen
        </a>
      </li>
      <li>
        <a className="index-link" href="#m225">
          Bereitstellung des Onlineangebotes und Webhosting
        </a>
      </li>
      <li>
        <a className="index-link" href="#m136">
          Präsenzen in sozialen Netzwerken (Social Media)
        </a>
      </li>
    </ul>
    <h2 id="m3">Verantwortlicher</h2>
    <p>
      Jonas Liebegott
      <br />
      Leverkusen, 51379
      <br />
    </p>
    E-Mail-Adresse:{" "}
    <a href="mailto:relations@jonaslbgtt.de">relations@jonaslbgtt.de</a>
    Impressum:{" "}
    <a href="https://jonaslbgtt.de/impressum" target="_blank">
      https://jonaslbgtt.de/impressum
    </a>
    <h2 id="mOverview">Übersicht der Verarbeitungen</h2>
    <p>
      Die nachfolgende Übersicht fasst die Arten der verarbeiteten Daten und die
      Zwecke ihrer Verarbeitung zusammen und verweist auf die betroffenen
      Personen.
    </p>
    <h3>Arten der verarbeiteten Daten</h3>
    <ul>
      <li>Kontaktdaten.</li>
      <li>Inhaltsdaten.</li>
      <li>Nutzungsdaten.</li>
      <li>Meta-, Kommunikations- und Verfahrensdaten.</li>
    </ul>
    <h3>Kategorien betroffener Personen</h3>
    <ul>
      <li>Nutzer.</li>
    </ul>
    <h3>Zwecke der Verarbeitung</h3>
    <ul>
      <li>Kontaktanfragen und Kommunikation.</li>
      <li>Sicherheitsmaßnahmen.</li>
      <li>Feedback.</li>
      <li>Marketing.</li>
      <li>Bereitstellung unseres Onlineangebotes und Nutzerfreundlichkeit.</li>
      <li>Informationstechnische Infrastruktur.</li>
    </ul>
    <h2 id="m2427">Maßgebliche Rechtsgrundlagen</h2>
    <p>
      <strong>Maßgebliche Rechtsgrundlagen nach der DSGVO: </strong>Im Folgenden
      erhalten Sie eine Übersicht der Rechtsgrundlagen der DSGVO, auf deren
      Basis wir personenbezogene Daten verarbeiten. Bitte nehmen Sie zur
      Kenntnis, dass neben den Regelungen der DSGVO nationale
      Datenschutzvorgaben in Ihrem bzw. unserem Wohn- oder Sitzland gelten
      können. Sollten ferner im Einzelfall speziellere Rechtsgrundlagen
      maßgeblich sein, teilen wir Ihnen diese in der Datenschutzerklärung mit.
    </p>
    <ul>
      <li>
        <strong>
          Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO)
        </strong>{" "}
        - Die Verarbeitung ist zur Wahrung der berechtigten Interessen des
        Verantwortlichen oder eines Dritten erforderlich, sofern nicht die
        Interessen oder Grundrechte und Grundfreiheiten der betroffenen Person,
        die den Schutz personenbezogener Daten erfordern, überwiegen.
      </li>
    </ul>
    <p>
      <strong>Nationale Datenschutzregelungen in Deutschland: </strong>
      Zusätzlich zu den Datenschutzregelungen der DSGVO gelten nationale
      Regelungen zum Datenschutz in Deutschland. Hierzu gehört insbesondere das
      Gesetz zum Schutz vor Missbrauch personenbezogener Daten bei der
      Datenverarbeitung (Bundesdatenschutzgesetz – BDSG). Das BDSG enthält
      insbesondere Spezialregelungen zum Recht auf Auskunft, zum Recht auf
      Löschung, zum Widerspruchsrecht, zur Verarbeitung besonderer Kategorien
      personenbezogener Daten, zur Verarbeitung für andere Zwecke und zur
      Übermittlung sowie automatisierten Entscheidungsfindung im Einzelfall
      einschließlich Profiling. Ferner können Landesdatenschutzgesetze der
      einzelnen Bundesländer zur Anwendung gelangen.
    </p>
    <p>
      <strong>Hinweis auf Geltung DSGVO und Schweizer DSG: </strong>Diese
      Datenschutzhinweise dienen sowohl der Informationserteilung nach dem
      schweizerischen Bundesgesetz über den Datenschutz (Schweizer DSG) als auch
      nach der Datenschutzgrundverordnung (DSGVO). Aus diesem Grund bitten wir
      Sie zu beachten, dass aufgrund der breiteren räumlichen Anwendung und
      Verständlichkeit die Begriffe der DSGVO verwendet werden. Insbesondere
      statt der im Schweizer DSG verwendeten Begriffe „Bearbeitung" von
      „Personendaten", "überwiegendes Interesse" und "besonders schützenswerte
      Personendaten" werden die in der DSGVO verwendeten Begriffe „Verarbeitung"
      von „personenbezogenen Daten" sowie "berechtigtes Interesse" und
      "besondere Kategorien von Daten" verwendet. Die gesetzliche Bedeutung der
      Begriffe wird jedoch im Rahmen der Geltung des Schweizer DSG weiterhin
      nach dem Schweizer DSG bestimmt.
    </p>
    <h2 id="m27">Sicherheitsmaßnahmen</h2>
    <p>
      Wir treffen nach Maßgabe der gesetzlichen Vorgaben unter Berücksichtigung
      des Stands der Technik, der Implementierungskosten und der Art, des
      Umfangs, der Umstände und der Zwecke der Verarbeitung sowie der
      unterschiedlichen Eintrittswahrscheinlichkeiten und des Ausmaßes der
      Bedrohung der Rechte und Freiheiten natürlicher Personen geeignete
      technische und organisatorische Maßnahmen, um ein dem Risiko angemessenes
      Schutzniveau zu gewährleisten.
    </p>
    <p>
      Zu den Maßnahmen gehören insbesondere die Sicherung der Vertraulichkeit,
      Integrität und Verfügbarkeit von Daten durch Kontrolle des physischen und
      elektronischen Zugangs zu den Daten als auch des sie betreffenden
      Zugriffs, der Eingabe, der Weitergabe, der Sicherung der Verfügbarkeit und
      ihrer Trennung. Des Weiteren haben wir Verfahren eingerichtet, die eine
      Wahrnehmung von Betroffenenrechten, die Löschung von Daten und Reaktionen
      auf die Gefährdung der Daten gewährleisten. Ferner berücksichtigen wir den
      Schutz personenbezogener Daten bereits bei der Entwicklung bzw. Auswahl
      von Hardware, Software sowie Verfahren entsprechend dem Prinzip des
      Datenschutzes, durch Technikgestaltung und durch datenschutzfreundliche
      Voreinstellungen.
    </p>
    <p>
      TLS/SSL-Verschlüsselung (https): Um die Daten der Benutzer, die über
      unsere Online-Dienste übertragen werden, zu schützen, verwenden wir
      TLS/SSL-Verschlüsselung. Secure Sockets Layer (SSL) ist die
      Standardtechnologie zur Sicherung von Internetverbindungen durch
      Verschlüsselung der zwischen einer Website oder App und einem Browser
      (oder zwischen zwei Servern) übertragenen Daten. Transport Layer Security
      (TLS) ist eine aktualisierte und sicherere Version von SSL. Hyper Text
      Transfer Protocol Secure (HTTPS) wird in der URL angezeigt, wenn eine
      Website durch ein SSL/TLS-Zertifikat gesichert ist.
    </p>
    <h2 id="m25">Übermittlung von personenbezogenen Daten</h2>
    <p>
      Im Rahmen unserer Verarbeitung von personenbezogenen Daten kommt es vor,
      dass die Daten an andere Stellen, Unternehmen, rechtlich selbstständige
      Organisationseinheiten oder Personen übermittelt oder sie ihnen gegenüber
      offengelegt werden. Zu den Empfängern dieser Daten können z.&nbsp;B. mit
      IT-Aufgaben beauftragte Dienstleister oder Anbieter von Diensten und
      Inhalten, die in eine Webseite eingebunden werden, gehören. In solchen
      Fällen beachten wir die gesetzlichen Vorgaben und schließen insbesondere
      entsprechende Verträge bzw. Vereinbarungen, die dem Schutz Ihrer Daten
      dienen, mit den Empfängern Ihrer Daten ab.
    </p>
    <h2 id="m24">Internationale Datentransfers</h2>
    <p>
      Datenverarbeitung in Drittländern: Sofern wir Daten in einem Drittland
      (d.&nbsp;h., außerhalb der Europäischen Union (EU), des Europäischen
      Wirtschaftsraums (EWR)) verarbeiten oder die Verarbeitung im Rahmen der
      Inanspruchnahme von Diensten Dritter oder der Offenlegung bzw.
      Übermittlung von Daten an andere Personen, Stellen oder Unternehmen
      stattfindet, erfolgt dies nur im Einklang mit den gesetzlichen Vorgaben.
      Sofern das Datenschutzniveau in dem Drittland mittels eines
      Angemessenheitsbeschlusses anerkannt wurde (Art. 45 DSGVO), dient dieser
      als Grundlage des Datentransfers. Im Übrigen erfolgen Datentransfers nur
      dann, wenn das Datenschutzniveau anderweitig gesichert ist, insbesondere
      durch Standardvertragsklauseln (Art. 46 Abs. 2 lit. c) DSGVO),
      ausdrückliche Einwilligung oder im Fall vertraglicher oder gesetzlich
      erforderlicher Übermittlung (Art. 49 Abs. 1 DSGVO). Im Übrigen teilen wir
      Ihnen die Grundlagen der Drittlandübermittlung bei den einzelnen Anbietern
      aus dem Drittland mit, wobei die Angemessenheitsbeschlüsse als Grundlagen
      vorrangig gelten. Informationen zu Drittlandtransfers und vorliegenden
      Angemessenheitsbeschlüssen können dem Informationsangebot der
      EU-Kommission entnommen werden:{" "}
      <a
        href="https://commission.europa.eu/law/law-topic/data-protection/international-dimension-data-protection_en?prefLang=de"
        target="_blank"
      >
        https://commission.europa.eu/law/law-topic/data-protection/international-dimension-data-protection_en?prefLang=de.
      </a>
    </p>
    <p>
      EU-US Trans-Atlantic Data Privacy Framework: Im Rahmen des sogenannten
      „Data Privacy Framework" (DPF) hat die EU-Kommission das Datenschutzniveau
      ebenfalls für bestimmte Unternehmen aus den USA im Rahmen der
      Angemessenheitsbeschlusses vom 10.07.2023 als sicher anerkannt. Die Liste
      der zertifizierten Unternehmen als auch weitere Informationen zu dem DPF
      können Sie der Webseite des Handelsministeriums der USA unter{" "}
      <a href="https://www.dataprivacyframework.gov/" target="_blank">
        https://www.dataprivacyframework.gov/
      </a>{" "}
      (in Englisch) entnehmen. Wir informieren Sie im Rahmen der
      Datenschutzhinweise, welche von uns eingesetzten Diensteanbieter unter dem
      Data Privacy Framework zertifiziert sind.
    </p>
    <h2 id="m10">Rechte der betroffenen Personen</h2>
    <p>
      Rechte der betroffenen Personen aus der DSGVO: Ihnen stehen als Betroffene
      nach der DSGVO verschiedene Rechte zu, die sich insbesondere aus Art. 15
      bis 21 DSGVO ergeben:
    </p>
    <ul>
      <li>
        <strong>
          Widerspruchsrecht: Sie haben das Recht, aus Gründen, die sich aus
          Ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung
          der Sie betreffenden personenbezogenen Daten, die aufgrund von Art. 6
          Abs. 1 lit. e oder f DSGVO erfolgt, Widerspruch einzulegen; dies gilt
          auch für ein auf diese Bestimmungen gestütztes Profiling. Werden die
          Sie betreffenden personenbezogenen Daten verarbeitet, um Direktwerbung
          zu betreiben, haben Sie das Recht, jederzeit Widerspruch gegen die
          Verarbeitung der Sie betreffenden personenbezogenen Daten zum Zwecke
          derartiger Werbung einzulegen; dies gilt auch für das Profiling,
          soweit es mit solcher Direktwerbung in Verbindung steht.
        </strong>
      </li>
      <li>
        <strong>Widerrufsrecht bei Einwilligungen:</strong> Sie haben das Recht,
        erteilte Einwilligungen jederzeit zu widerrufen.
      </li>
      <li>
        <strong>Auskunftsrecht:</strong> Sie haben das Recht, eine Bestätigung
        darüber zu verlangen, ob betreffende Daten verarbeitet werden und auf
        Auskunft über diese Daten sowie auf weitere Informationen und Kopie der
        Daten entsprechend den gesetzlichen Vorgaben.
      </li>
      <li>
        <strong>Recht auf Berichtigung:</strong> Sie haben entsprechend den
        gesetzlichen Vorgaben das Recht, die Vervollständigung der Sie
        betreffenden Daten oder die Berichtigung der Sie betreffenden
        unrichtigen Daten zu verlangen.
      </li>
      <li>
        <strong>Recht auf Löschung und Einschränkung der Verarbeitung:</strong>{" "}
        Sie haben nach Maßgabe der gesetzlichen Vorgaben das Recht, zu
        verlangen, dass Sie betreffende Daten unverzüglich gelöscht werden, bzw.
        alternativ nach Maßgabe der gesetzlichen Vorgaben eine Einschränkung der
        Verarbeitung der Daten zu verlangen.
      </li>
      <li>
        <strong>Recht auf Datenübertragbarkeit:</strong> Sie haben das Recht,
        Sie betreffende Daten, die Sie uns bereitgestellt haben, nach Maßgabe
        der gesetzlichen Vorgaben in einem strukturierten, gängigen und
        maschinenlesbaren Format zu erhalten oder deren Übermittlung an einen
        anderen Verantwortlichen zu fordern.
      </li>
      <li>
        <strong>Beschwerde bei Aufsichtsbehörde:</strong> Sie haben unbeschadet
        eines anderweitigen verwaltungsrechtlichen oder gerichtlichen
        Rechtsbehelfs das Recht auf Beschwerde bei einer Aufsichtsbehörde,
        insbesondere in dem Mitgliedstaat ihres gewöhnlichen Aufenthaltsorts,
        ihres Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes, wenn Sie
        der Ansicht sind, dass die Verarbeitung der Sie betreffenden
        personenbezogenen Daten gegen die Vorgaben der DSGVO verstößt.
      </li>
    </ul>
    <h2 id="m225">Bereitstellung des Onlineangebotes und Webhosting</h2>
    <p>
      Wir verarbeiten die Daten der Nutzer, um ihnen unsere Online-Dienste zur
      Verfügung stellen zu können. Zu diesem Zweck verarbeiten wir die
      IP-Adresse des Nutzers, die notwendig ist, um die Inhalte und Funktionen
      unserer Online-Dienste an den Browser oder das Endgerät der Nutzer zu
      übermitteln.
    </p>
    <ul className="m-elements">
      <li>
        <strong>Verarbeitete Datenarten:</strong> Nutzungsdaten (z.&nbsp;B.
        besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten); Meta-,
        Kommunikations- und Verfahrensdaten (z.&nbsp;.B. IP-Adressen,
        Zeitangaben, Identifikationsnummern, Einwilligungsstatus).
      </li>
      <li>
        <strong>Betroffene Personen:</strong> Nutzer (z.&nbsp;.B.
        Webseitenbesucher, Nutzer von Onlinediensten).
      </li>
      <li>
        <strong>Zwecke der Verarbeitung:</strong> Bereitstellung unseres
        Onlineangebotes und Nutzerfreundlichkeit; Informationstechnische
        Infrastruktur (Betrieb und Bereitstellung von Informationssystemen und
        technischen Geräten (Computer, Server etc.).). Sicherheitsmaßnahmen.
      </li>
      <li className="">
        <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1
        S. 1 lit. f) DSGVO).
      </li>
    </ul>
    <p>
      <strong>
        Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:
      </strong>
    </p>
    <ul className="m-elements">
      <li>
        <strong>
          Bereitstellung Onlineangebot auf eigener/ dedizierter Serverhardware:{" "}
        </strong>
        Für die Bereitstellung unseres Onlineangebotes nutzen wir von uns
        betriebene Serverhardware sowie den damit verbundenen Speicherplatz, die
        Rechenkapazität und die Software;{" "}
        <span className="">
          <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs.
          1 S. 1 lit. f) DSGVO).
        </span>
      </li>
      <li>
        <strong>Erhebung von Zugriffsdaten und Logfiles: </strong>Der Zugriff
        auf unser Onlineangebot wird in Form von so genannten "Server-Logfiles"
        protokolliert. Zu den Serverlogfiles können die Adresse und Name der
        abgerufenen Webseiten und Dateien, Datum und Uhrzeit des Abrufs,
        übertragene Datenmengen, Meldung über erfolgreichen Abruf, Browsertyp
        nebst Version, das Betriebssystem des Nutzers, Referrer URL (die zuvor
        besuchte Seite) und im Regelfall IP-Adressen und der anfragende Provider
        gehören. Die Serverlogfiles können zum einen zu Zwecken der Sicherheit
        eingesetzt werden, z.&nbsp;B., um eine Überlastung der Server zu
        vermeiden (insbesondere im Fall von missbräuchlichen Angriffen,
        sogenannten DDoS-Attacken) und zum anderen, um die Auslastung der Server
        und ihre Stabilität sicherzustellen;{" "}
        <span className="">
          <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs.
          1 S. 1 lit. f) DSGVO).{" "}
        </span>
        <strong>Löschung von Daten:</strong> Logfile-Informationen werden für
        die Dauer von maximal 30 Tagen gespeichert und danach gelöscht oder
        anonymisiert. Daten, deren weitere Aufbewahrung zu Beweiszwecken
        erforderlich ist, sind bis zur endgültigen Klärung des jeweiligen
        Vorfalls von der Löschung ausgenommen.
      </li>
    </ul>
    <h2 id="m136">Präsenzen in sozialen Netzwerken (Social Media)</h2>
    <p>
      Wir unterhalten Onlinepräsenzen innerhalb sozialer Netzwerke und
      verarbeiten in diesem Rahmen Daten der Nutzer, um mit den dort aktiven
      Nutzern zu kommunizieren oder um Informationen über uns anzubieten.
    </p>
    <p>
      Wir weisen darauf hin, dass dabei Daten der Nutzer außerhalb des Raumes
      der Europäischen Union verarbeitet werden können. Hierdurch können sich
      für die Nutzer Risiken ergeben, weil so z.&nbsp;B. die Durchsetzung der
      Rechte der Nutzer erschwert werden könnte.
    </p>
    <p>
      Ferner werden die Daten der Nutzer innerhalb sozialer Netzwerke im
      Regelfall für Marktforschungs- und Werbezwecke verarbeitet. So können
      z.&nbsp;B. anhand des Nutzungsverhaltens und sich daraus ergebender
      Interessen der Nutzer Nutzungsprofile erstellt werden. Die Nutzungsprofile
      können wiederum verwendet werden, um z.&nbsp;B. Werbeanzeigen innerhalb
      und außerhalb der Netzwerke zu schalten, die mutmaßlich den Interessen der
      Nutzer entsprechen. Zu diesen Zwecken werden im Regelfall Cookies auf den
      Rechnern der Nutzer gespeichert, in denen das Nutzungsverhalten und die
      Interessen der Nutzer gespeichert werden. Ferner können in den
      Nutzungsprofilen auch Daten unabhängig der von den Nutzern verwendeten
      Geräte gespeichert werden (insbesondere, wenn die Nutzer Mitglieder der
      jeweiligen Plattformen sind und bei diesen eingeloggt sind).
    </p>
    <p>
      Für eine detaillierte Darstellung der jeweiligen Verarbeitungsformen und
      der Widerspruchsmöglichkeiten (Opt-Out) verweisen wir auf die
      Datenschutzerklärungen und Angaben der Betreiber der jeweiligen Netzwerke.
    </p>
    <p>
      Auch im Fall von Auskunftsanfragen und der Geltendmachung von
      Betroffenenrechten weisen wir darauf hin, dass diese am effektivsten bei
      den Anbietern geltend gemacht werden können. Nur die Anbieter haben
      jeweils Zugriff auf die Daten der Nutzer und können direkt entsprechende
      Maßnahmen ergreifen und Auskünfte geben. Sollten Sie dennoch Hilfe
      benötigen, dann können Sie sich an uns wenden.
    </p>
    <ul className="m-elements">
      <li>
        <strong>Verarbeitete Datenarten:</strong> Kontaktdaten (z.&nbsp;B.
        E-Mail, Telefonnummern); Inhaltsdaten (z.&nbsp;B. Eingaben in
        Onlineformularen); Nutzungsdaten (z.&nbsp;B. besuchte Webseiten,
        Interesse an Inhalten, Zugriffszeiten); Meta-, Kommunikations- und
        Verfahrensdaten (z.&nbsp;.B. IP-Adressen, Zeitangaben,
        Identifikationsnummern, Einwilligungsstatus).
      </li>
      <li>
        <strong>Betroffene Personen:</strong> Nutzer (z.&nbsp;.B.
        Webseitenbesucher, Nutzer von Onlinediensten).
      </li>
      <li>
        <strong>Zwecke der Verarbeitung:</strong> Kontaktanfragen und
        Kommunikation; Feedback (z.&nbsp;B. Sammeln von Feedback via
        Online-Formular). Marketing.
      </li>
      <li className="">
        <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1
        S. 1 lit. f) DSGVO).
      </li>
    </ul>
    <p className="seal">
      <a
        href="https://datenschutz-generator.de/"
        title="Rechtstext von Dr. Schwenke - für weitere Informationen bitte anklicken."
        target="_blank"
        rel="noopener noreferrer nofollow"
      >
        Erstellt mit kostenlosem Datenschutz-Generator.de von Dr. Thomas
        Schwenke
      </a>
    </p>
  </>
);

export default DSGVO;
