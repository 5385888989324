import React from "react";
import Router from "./wrapper/Router";

import "bootstrap/dist/css/bootstrap.min.css";
function App() {
  return (
    <div
      style={{
        backgroundColor: "0f0", // Dunkle Farbe
        color: "#fff", // Helle Textfarbe
        width: "100vw",
        height: "100vh",
      }}
    >
      <Router />
    </div>
  );
}
export default App;
