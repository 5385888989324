//This is my contact page
import React from "react";
import { Link } from "react-router-dom";
import Txt from "../components/Txt";
import { TxtEmail, TxtLink } from "../components/Txt";
const Contact = () => {
  return (
    <>
      {TxtEmail("Schreib mir unter", "relations@jonaslbgtt.de")}
      {TxtLink("https://github.com/21jon", "Mein GitHub")}
    </>
  );
};
export default Contact;
