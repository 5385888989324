import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Home from "../pages/Home";
import About from "../pages/CV";
import Contact from "../pages/Contact";
import Taskbar from "../components/Taskleiste";
import Datenschutz from "../pages/Datenschutz";
function AppRouter() {
  return (
    <div
      style={{ backgroundColor: "#111", color: "#00ff00", minHeight: "100vh" }}
    >
      <Router>
        <Taskbar />
        <Routes>
          <Route path="/cv" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/" element={<Home />} />
          <Route path="/datenschutz" element={<Datenschutz></Datenschutz>} />
        </Routes>
      </Router>
    </div>
  );
}

export default AppRouter;
