const Txt = (text) => {
  return (
    <>
      <p>guest@jonasbgtt.de{'> echo "' + text + '"'}</p> <p>{text}</p>
    </>
  );
};

const TxtEmail = (text, email) => {
  return (
    <>
      <p>
        echo "guest@jonasbgtt.de{"> " + text}:{" "}
        <a href={"mailto:" + email}>{email}</a>
        {'" '}
      </p>
      <p>
        "guest@jonasbgtt.de{"> " + text}:{" "}
        <a href={"mailto:" + email}>{email}</a>
        {'" '}
      </p>
    </>
  );
};

const TxtLink = (link, website) => {
  return (
    <>
      <p>
        guest@jonasbgtt.de echo{"> "} "<a href={link}>{website}</a>"
      </p>
      <p>
        <a href={link} target="_blank">
          {website}
        </a>
      </p>
    </>
  );
};
export default Txt;
export { TxtEmail, TxtLink };
