// this ist the home page
import React from "react";
import Txt from "../components/Txt";
import { Box, Card } from "react-bootstrap";

const Home = () => {
  const txt = [
    "Hallo",
    "Das ist meine Seite yikes",
    "Hier ist noch nicht viel los",
    "Aber das wird sich ändern",
    "WIP",
  ];

  return <>{txt.map((a) => Txt(a))}</>;
};

export default Home;
