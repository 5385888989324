import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { LinkContainer } from "react-router-bootstrap";

function BasicExample() {
  return (
    <Navbar
      expand="lg"
      className="bg-body-tertiary"
      sticky="top"
      data-bs-theme="dark"
    >
      <Container>
        <LinkContainer to="/">
          <Navbar.Brand>Home</Navbar.Brand>
        </LinkContainer>

        <LinkContainer to="/cv">
          <Nav.Link>About</Nav.Link>
        </LinkContainer>
        <LinkContainer to="/contact">
          <Nav.Link>Contact</Nav.Link>
        </LinkContainer>
        <LinkContainer to="/datenschutz">
          <Nav.Link>Datenschutz</Nav.Link>
        </LinkContainer>
      </Container>
    </Navbar>
  );
}

export default BasicExample;
