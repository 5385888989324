// This is my CV page

import React from "react";
import { Link } from "react-router-dom";

const CV = () => {
  return (
    <div>
      <h1>CV</h1>
      <Link to="/">Home</Link>
    </div>
  );
};

export default CV;
